<template>
    <div>
        <div class="pb-6 diagonal pb-8 pt-0 pt-md-5" >
            <div class="row">
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col">


                    <div id="components-form-demo-advanced-search" > <a-form class="ant-advanced-search-form" :form="form"  @submit="handleFilter">
      <a-row :gutter="24">
        <a-col :span="2" style="block">
            <a-form-item label="Rok" :hasFeedback="false">
                        <a-select type="text"
                        v-decorator="[
                `selectedYear`,
                { initialValue: selectedYear }
              ]" >
                            <template v-for="year in exposeYear()">
                                <a-select-option :value="year" :key="year">{{year}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-item>
                </a-col>
          <a-col :span="2" style="block">
                   <a-form-item label="Mesiac" :hasFeedback="false">
                        <a-select type="text"       v-decorator="[
                `selectedMonth`,
                { initialValue: selectedMonth }
              ]" >
                       <a-select-option value="" key="0">Celý rok</a-select-option>

                            <template v-for="month in 12">
                                <a-select-option :value="month" :key="month">{{new Date(selectedYear + '-' + month +'-1').toLocaleString('sk-SK',{month:'long'})}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-item>
        </a-col>
          <a-col :span="4" style="block">
           <a-form-item :hasFeedback="false" label="Číslo objednávky">
           <a-input class="w-100"
       v-decorator="[
                `orderNumber`
              ]"
      placeholder="Zadajte celé, alebo časť čísla objednávky"

    />
          </a-form-item>
        </a-col>
            <a-col :span="5" style="block">
           <a-form-item :hasFeedback="false" :hasSuccess="false"  label="Typ platby">
           <a-select
      mode="multiple"
       v-decorator="[
                `orderPayment`
              ]"
    >
      <a-select-option v-for="i in order_payments" :key="i.value">{{i.label}}</a-select-option>
    </a-select>
          </a-form-item>
        </a-col>
          <a-col :span="5" style="block">
           <a-form-item :hasFeedback="false"  label="Stav objednávky">
           <a-select
      mode="multiple"
       v-decorator="[
                `orderStatus`
              ]"
    >
      <a-select-option v-for="i in order_statuses" :key="i.value">{{i.label}}</a-select-option>
    </a-select>
          </a-form-item>
        </a-col>
     <a-col :span="5" style="block">
           <a-form-item :hasFeedback="false"  label="Fulltextové vyhľadávanie">
           <a-input
       v-decorator="[
                `fulltextSearch`
              ]"
      placeholder="Meno, priezvisko, adresa, email, telefón ..."
    >
    </a-input>
          </a-form-item>
        </a-col>



      </a-row>
      <a-row>
        <a-col :span="12" :style="{ textAlign: 'left', paddingLeft: '5px' }">
         <span class="text-muted" v-if="!loading">Počet faktúr: {{data.length}}</span>
        <span  class="text-muted ml-3" v-if="filteredprice > 0">Suma: {{filteredprice.toLocaleString()}} €</span>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
             <a-button  @click="handleResetMF">
            Reštart
          </a-button>
          <a-button type="primary" :style="{ marginLeft: '8px' }" html-type="submit">
            Filtrovať
          </a-button>
        </a-col>
      </a-row>
    </a-form>
</div>

                    <div class="card shadow" style="border-top-left-radius:0;border-top-right-radius:0">
                        <div class="table-responsive">
                            <a-table
                                    :columns="columns"
                                    :dataSource="data"
                                    :pagination="pagination"
                                    :loading="loading"
                                    :rowKey="record => record.id"
                                    class="table-flush">

                                <template slot="payment" slot-scope="payment">
                                    <div>
                                        <a-tag v-if="payment === 'cod'" color="">Recepcia</a-tag>
                                        <a-tag v-if="payment === 'payout'"  color="green">Karta</a-tag>
                                        <a-tag v-if="payment === 'cardpay'"  color="green">Karta</a-tag>
                                        <a-tag v-if="payment === 'cod' || payment == 'cashondelivery'"  color="blue">Dobierka</a-tag>
                                        <a-tag v-if="payment === 'webpay'"  color="green">Karta</a-tag>
                                        <a-tag v-if="payment === 'bank'"  color="red">Bankový prevod</a-tag>
                                    </div>
                                </template>

                                <template slot="prices" slot-scope="price, record">
                                    <div v-if="price>0">{{Number(price).toFixed(2) + ' €'}}</div>
                                    <div v-if="record.transaction" class="paid" title="nastavená záloha">({{Number(record.transaction.amount).toFixed(2) + ' €'}})</div>
                                </template>

                                <template slot="status" slot-scope="payment,record">
                                    <div>
                                        <a-tag v-if="payment === 'pending'" color="green">Objednávka </a-tag>
                                        <a-tag v-if="payment === 'payment_recieved'"  color="orange">Prijatá platba</a-tag>
                                        <a-tag v-if="payment === 'in_checkout' && moment(record.created).isAfter(moment().subtract(20, 'minutes'))"  color="">Platobná brána</a-tag>
                                        <a-tag v-if="payment === 'in_checkout' && !moment(record.created).isAfter(moment().subtract(20, 'minutes'))"  color="">Opustená</a-tag>
                                        <a-tag v-if="payment === 'storno'"  color="red">Stornovaná</a-tag>
                                        <a-tag v-if="payment === 'send'"  color="blue">Odoslané</a-tag>
                                        <a-tag v-if="payment === 'abandoned'"  color="red">Opustená</a-tag>
                                        <a-tag v-if="payment === 'completed'"  color="green">Kompletná</a-tag>
                                    </div>
                                </template>
                                <template  slot="vs" slot-scope="vs">
                                    <a-tag style="display:inline-block;clear:both" title="Variabilný symbol">{{vs}}</a-tag>
                                </template>
                                <template slot="date" slot-scope="datedata">
                                    <span>{{formatDate(datedata)}}</span>
                                </template>
                                <template slot="pdf" slot-scope="pdf,record">
                                    <template v-for="inv in pdf">
                                    <a target="_blank" :key="inv.id" class="mr-1" :href="send + '../../../' + inv.pdf">
                                        <a-button  type="secondary" size="small" class="ml-1"><i class="fas fa-file-pdf mr-1"></i> <span v-if="inv.preinvoice == 1">PF</span><span v-else>FA</span>{{ inv.invoice_number }}</a-button></a>
                                        <router-link :to="'/invoice/' + record.order_id"   :href="'/invoice/' + record.order_id"><a-button size="small">Detail</a-button></router-link>
                                        </template>
                                    </template>

                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {API_URL} from "../api";
    import {dataService} from "../_services";
    import {Button, Input, Icon, Tag, Row, Col, Select, Form} from 'ant-design-vue';

    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import moment from 'moment'

    Vue.use(FormItem);
    export default {
        name: "deskreservations",
        components: {
            "a-button": Button,
            "a-icon": Icon,
            "a-tag": Tag,
            "a-input": Input,
            "a-select": Select,
            "a-select-option": Select.Option,
            "a-row": Row,
            "a-col": Col,
            "a-form": Form,
            "a-form-item": Form.Item,
        },
        data() {
            return {
                data: [],
                searchText: '',
                searchInput: null,
                exportFormat: '',
                filteredprice:0,
                order_statuses: [
                    {"value": "pending","label": "Objednávka"},
                    {"value": "payment_recieved","label": "Prijatá platba"},
                    {"value": "completed","label": "Kompletná"},
                    {"value": "abandoned","label": "Opustená objednávka"},
                    {"value": "in_checkout","label": "Na platobnej bráne"},
                    {"value": "storno","label": "Stornovaná objednávka"},
                    ],
                send: API_URL,
                roleops: [],
                colorsto: JSON.parse(localStorage.colors),
                loading: false,
                form: this.$form.createForm(this, { name: 'advanced_search' }),
                selectedMonth: parseInt(new Date().getMonth())+1,
                refreshitems:0,
                order_payments: [
                    {"value": "cod","label": "Platba na mieste"},
                    {"value": "card","label": "Platba kartou"},
                    {"value": "bank","label": "Platba Bankovým prevodom"},
                    ],
                years:[],
                selectedYear:  parseInt(new Date().getFullYear()),
                formItemLayout: {
                    labelCol: {
                        xs: {span: 10},
                        sm: {span: 10},
                    },
                    wrapperCol: {
                        xs: {span: 14},
                        sm: {span: 14},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },  pagination: {
                    defaultPageSize: 25
                },
                columns: [
                    {
                        title: 'Obj č.',
                        dataIndex: 'order_id',
                        key: 'name',
                        width: '5%',
                    },
                    {
                        title: 'Objednávka vytvorená',
                        key: 'created',
                        dataIndex: 'created'
                    },
                    {
                        title: 'Meno',
                        key: 'meno',
                        dataIndex: 'full_name',
                    },
                    {
                        title: 'Firma',
                        key: 'firma',
                        dataIndex: 'billing_company',
                    },
                    {
                        title: 'Číslo faktúry',
                        key: 'invoice_number',
                        dataIndex: 'invoice_number',
                    },
                    {
                        title: 'Stav',
                        dataIndex: 'order_status',
                        width: '10%',
                        scopedSlots: {customRender: 'status'},
                    },
                    {
                        title: 'Typ platby',
                        dataIndex: 'payment_method',
                        width: '10%',
                        scopedSlots: {customRender: 'payment'},
                    },
                    {
                        title: 'Variabilný symbol',
                        dataIndex: 'vs',
                        width: '10%',
                        scopedSlots: {customRender: 'vs'},
                    },
                    {
                        title: 'Suma',
                        dataIndex: 'price',
                        width: '10%',
                        scopedSlots: {customRender: 'prices'},
                    },
                    {
                        title: 'Faktúra',
                        align: 'right',
                        dataIndex: 'invoices',
                        scopedSlots: {customRender: 'pdf'},
                    }
                ],
            };
        },

        mounted() {

                this.fetch();


        },
        methods: {
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            handleFilter(e) {
    e.preventDefault();
    this.PostFetch();
},
    PostFetch()
    {
this.form.validateFields((err, values) => {
        if (!err) {
   this.loading = true;
   this.filteredprice = 0;
                dataService.axiosPost('invoices',values).then(results => {
                    this.data = results.data.data;
                    this.loading = false;
var roleops = 0;
                    results.data.data.forEach(obj => {
                      if(obj.price)
                        roleops  =  roleops + parseFloat(obj.price); });

this.filteredprice = roleops;

                });
        }
        });
    },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];
                console.log(this.searchText);
            },
            handleResetMF() {
      this.form.resetFields();
      this.PostFetch();
    },
    exposeYear()
    {

let years = [];
var fromyear = parseInt(new Date().getFullYear()) - 10;
var toyear = parseInt(new Date().getFullYear());

var date = new Date();
for(let year = fromyear; year <= toyear; year++){ years.push(year) }

return years;
    },
base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
},
            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            forceFileDownload(response){
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.png') //or any other extension
                document.body.appendChild(link)
                link.click()
            },
            forcePDFDownload(response){
 var arrBuffer = this.base64ToArrayBuffer(response.file);

                const url = window.URL.createObjectURL(new Blob([arrBuffer], {type:"application/pdf"}))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', response.filename) //or any other extension
                document.body.appendChild(link)
                link.click()
            },
            downloadDocument(pdf)
            {
 dataService.axiosFetch('getinvoicefile/' + pdf).then(results => {

     this.forcePDFDownload(results);
     });
            },
            exportInvoices()
            {
console.log(this.exportFormat);
this.form.validateFields((err, values) => {

dataService.axiosPost('export/invoices/'+this.exportFormat,values).then(results => {
                    console.log(results);
    let byte = results.data[0];

    console.log(byte);
    var reportName = 'export.xml';
                    var blob = new Blob([byte], {type: "application/xml"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();

                });
            });
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch('invoices').then(results => {
                    this.data = results;
                    this.loading = false;

                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .highlight {
        background-color: rgb(255, 192, 105);
        padding: 0px;
    }


    .product {
        width: 100%;
        clear:both
    }

    .ant-advanced-search-form {
  padding: 15px;
  background: #fdfdfd;
  border: 0;
  border-radius: 6px;
  border-bottom-left-radius:0;
  border-bottom-right-radius:0
}

.ant-table-small
{border:0;
border-radius:0;}
.has-success:after
{display:none}
.ant-advanced-search-form .ant-form-item {
  display: block;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
    .product h4 {
        margin: 0;
        font-size: 12px;
    }

    .product h4.type-product {
        font-size: 12px;
    }

    .product h4.type-voucher {
        display:none
    }

    .product h4 span {
        float: right;
        font-size: 12px;
        padding-top: 5px;
    }

    .product p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2px;
        color: #000000;

    }


    .product:not(.item-0) h4 {
        padding-top: 2px;
    }


    .ant-table-pagination.ant-pagination {
        margin-bottom: 40px;
        margin-right: 20px;
    }

    .ant-pagination-item {
        border: 0;
    }

    .ant-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 20px;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a, .ant-pagination-jump-prev a, .ant-pagination-jump-next a {
        -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        border: 0;
    }

    .ant-pagination-item a, .ant-pagination-prev a, .ant-pagination-next a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0;
        margin: 0 3px;
        border-radius: 50% !important;
        width: 36px;
        height: 36px;
        font-size: 0.875rem;
    }

    .ant-pagination-item.ant-pagination-item-active a {
        z-index: 1;
        color: #fff;
        background-color: #5e72e4;
        border-color: #5e72e4;
    }

    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
